import React from 'react';
import './Advantage.css';
import AdvantageCard from "./advantagecard/AdvantageCard";
import Button from "../../button/Button";

const Advantage: React.FC = () => {
    return (
        <div className="advantage-container">
            <div className="advantage-block-title">
                Почему клиенты выбирают нас
            </div>
            <div className="advantage-card-wrapper">
                <AdvantageCard title={"ОПЛАТА ПО СЛОЖНЫМ ТОBАРНЫМ ПОЗИЦИЯМ"}
                               text={"Мы принимаем платежи по товарам «сложных» товарных групп выбирая для вас оптимальный способ перевода средств учитывая особенности товарных отношений"}></AdvantageCard>

                <AdvantageCard title={"НЕПРЕРЫBНЫЙ РЕЖИМ РАБОТЫ И ПЕРСОНАЛЬНЫЙ МЕНЕДЖЕР"}
                               text={"Каждому клиенту назначается персональный менеджер, который сопровождает сделку на всех этапах, обеспечивая быстрое и комфортное взаимодействие"}></AdvantageCard>

                <AdvantageCard title={"СРОКИ ПЕРЕBОДОB"}
                               text={"Денежные средства поступают зарубежному контрагенту в срок от 1 до 5 дней, в зависимости от выбранной схемы платежа, что обеспечивает гибкость и оперативность"}></AdvantageCard>

                <AdvantageCard title={"РАЗЛИЧНЫЕ СХЕМЫ ПЕРЕBОДОB"}
                               text={"Мы предлагаем различные решения для перевода денежных средств из России за рубеж. Наши эксперты помогут вам выбрать наиболее подходящий способ для вашего бизнеса"}></AdvantageCard>

                <Button text={"Связаться с нами"} href={"#contact"} additionalClassname={"button-main-block2"}></Button>
            </div>
        </div>
    );
};
export default Advantage;
