import React from 'react';
import './MainBlock3.css';

const MainBlock3: React.FC = () => {
    return (
        <div className="main-block-3" id="main-block-3">
            <div className="main-block-3-title">
                <h2>Коротко       о ситуации</h2>
            </div>
            <div className="main-block-3-main-info">
                <p>Компания «Манитод» готова взять на себя обязательства по переводу в короткие сроки
                    Ваших средств за рубеж на счета Ваших поставщиков. Мы готовы обеспечить чистоту операции
                    без рисков обнаружения российского следа</p>
            </div>
            <div className="main-block-3-sub-info">
                <p>Международные платежи из России в Китай
                    в 2024 году сталкиваются с серьёзными трудностями из-за санкций США и страха китайских банков перед вторичными санкциями. Большинство крупных и региональных банков Китая перестали принимать переводы
                    от российских компаний, в результате чего российский бизнес испытывает проблемы
                    как возврат платежей, замораживание средств и потеря финансов на конвертации валюты.

                    Переводы часто возвращаются обратно в Россию, что приводит к значительным потерям</p>

                <p>времени и средств на конвертацию валют.
                    Из-за этого российские компании вынуждены искать обходные схемы, такие как переводы через третьи страны или использование крипто валюты, хотя эти методы не всегда работают и могут нести высокие риски​.

                    С декабря 2023 года многие крупные китайские банки, такие как ICBC и China Construction Bank, прекратили принимать платежи из России, даже в юанях. В 2024 году эта тенденция усилилась, в результате около 98% китайских банков не работают с платежами из России.</p>
            </div>
        </div>
    );
};

export default MainBlock3;