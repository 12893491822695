import React, {useEffect} from 'react';
import './App.css';
import Header from '../src/header/Header'
import MainBlock from '../src/mainblock/MainBlock'
import WebFont from 'webfontloader'
import MainBlock2 from "./mainblock2/MainBlock2";
import MainBlock3 from "./mainblock3/MainBlock3";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";

function App() {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto'],
            },
            custom: {
                families: ['My Custom Font'],
                urls: ['/path-to-font.css'],
            },
            active: () => {
                console.log('Шрифты загружены');
            },
            inactive: () => {
                console.log('Не удалось загрузить шрифты');
            }
        });
    }, []);

    return (
        <div className="App">
            <Header/>
            <MainBlock/>
            <MainBlock2/>
            <MainBlock3/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
