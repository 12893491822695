import React from "react";
import "./Contact.css";
import ContactCard from "./card/contactCard";
import Request from "./request/Request";

const Contact: React.FC = () => {
    return (
        <div className="contact" id="contact">
            <div className="contact-title">
                <h2>Наши контакты и обратная связь</h2>
            </div>
            <div className="contact-wrapper">
                <ContactCard title="Телефон" text="+7 (981) 043 35 42"/>
                <ContactCard title="email" text="Mtoad.spb@yandex.ru"/>
                <ContactCard title="Адрес" text="г.Санкт-Петербург, наб. Мартынова, д.12"/>
            </div>

            <div>
                <Request></Request>
            </div>
        </div>
    );
}

export default Contact;