import React from 'react';
import './Button.css';
type ButtonComponent= {
    text:string
    href:string
    additionalClassname: string
}
const Button:React.FC<ButtonComponent> = (props) => {
    return (
        <a href={props.href} className={`button ${props.additionalClassname}`}>
            {props.text}
        </a>
    );
};

export default Button;