export interface ContactFormData {
    phoneNumber: string;
    fio: string;
    email: string;
    comment: string;
}

export const sendContactForm = async (data: ContactFormData): Promise<boolean> => {
    try {

        const response = await fetch('https://api.mtoad.ru/api/feedback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            console.log('Запрос выполнен успешно');
            return true;
        } else {
            console.error('Ошибка при отправке данных:', await response.text());
            return false;
        }
    } catch (error) {
        console.error('Ошибка при обращении к серверу:', error);
        return false;
    }
};
