import React, {FC} from 'react';
import './contactCard.css';
type ContactCardProps = {
    title: string,
    text: string
}
const ContactCard: FC<ContactCardProps> = (props) => {
    return (
        <div className="contact-card-wrapper">
            <p className="contact-card-title">{props.title}</p>
            <p className="contact-card-text">{props.text}</p>
        </div>
    );
};

export default ContactCard;