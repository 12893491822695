import React, {useRef, useState} from "react";
import "./Request.css";
import { sendContactForm} from './api/contactApi';
import InputMask from 'react-input-mask';
const Request: React.FC = () => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [fio, setFio] = useState('');

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const handleFioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFio(e.target.value);
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const buttonClick = () => {
        if (buttonRef.current) {
            buttonRef.current.disabled = true;
        }
        sendContactForm({phoneNumber: phoneNumber, fio:fio, email:"", comment:""})
        .then(() => {
            alert("Заявка успешно отправлена")
        }).catch(() => {
            alert("Не удалось отправить заявку")
        });
    }


    return (
        <div className="request-wrapper">
            <div>
                <div className="request-text-form">
                    <h3 className="request-title">Оставьте заявку</h3>
                    <p className="request-text">Заполните поля ниже, чтобы мы смогли с вами связаться
                        и уточнить все детали</p>
                </div>
                <div className="request-form-wrapper">
                    <div className="request-form">
                        <input placeholder="Введите ваше ФИО" type="text" pattern="" onChange={handleFioChange}/>
                        <InputMask
                            mask="+7 (999) 999-9999"
                            placeholder="Номер телефона"
                            onChange={handlePhoneChange}
                        ></InputMask>
                    </div>
                    <p>Оставляя свои данные, Вы принимаете условия политики конфиденциальности</p>
                </div>

                <div className="request-button-wrapper">
                    <button ref={buttonRef} className="request-button" onClick={() => buttonClick()}>Отправить</button>
                </div>
            </div>
        </div>
    );
}

export default Request;