import React from 'react';
import './MainBlock2.css';
import Advantage from "./advantage/Advantage";

const MainBlock2: React.FC = () => {
    return (
        <div className="MainBlock2" id="main-block2">
            <Advantage></Advantage>
        </div>
    );
};
export default MainBlock2;