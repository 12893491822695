import React from 'react';
import './MainBlock.css';
import Button from "../button/Button";

const MainBlock: React.FC = () => {
    return (
        <div className="main-block">
            <svg height="615" viewBox="0 0 1440 615" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_33_117)">
                    <path
                        d="M581.567 509.806C770.032 297.431 1034.82 382.83 1143.65 452.076C1203.71 620.741 1271.54 892.614 1062.37 630.784C800.91 303.497 345.986 775.276 581.567 509.806Z"
                        fill="#0353A4"/>
                </g>
                <g filter="url(#filter1_f_33_117)">
                    <path
                        d="M615.474 206.808C377.733 362.055 144.783 209.939 58.0264 114.476C44.505 -64.0521 50.6702 -344.19 183.502 -36.5181C349.542 348.072 912.651 12.7499 615.474 206.808Z"
                        fill="#006494"/>
                </g>
                <g filter="url(#filter2_f_33_117)">
                    <path
                        d="M786.464 267.297C997.157 144.419 1137.75 274.499 1181.71 354.899C1381.75 480.737 1677.2 684.016 1258.64 490.427C735.439 248.441 667.163 670.134 260.927 769.128C-145.309 868.122 -105.274 -579.079 235.022 -157.961C575.318 263.157 523.098 420.895 786.464 267.297Z"
                        fill="#023E7D"/>
                </g>
                <g filter="url(#filter3_f_33_117)">
                    <path
                        d="M845 583.417C785.176 518.984 600.338 423.681 339.574 557.931C13.6202 725.743 738.967 748.092 845 583.417Z"
                        fill="#0466C8"/>
                </g>
                <g filter="url(#filter4_f_33_117)">
                    <path
                        d="M802.506 -233.41C866.138 -84.6078 904.003 204.209 546.406 169.061C99.4103 125.127 450.959 -262.83 802.506 -233.41Z"
                        fill="#0353A4"/>
                </g>
                <defs>
                    <filter id="filter0_f_33_117" x="342.967" y="202.083" width="1034.07" height="710.448"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="86.5" result="effect1_foregroundBlur_33_117"/>
                    </filter>
                    <filter id="filter1_f_33_117" x="-119.902" y="-346.907" width="993.521" height="793.328"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="86.5" result="effect1_foregroundBlur_33_117"/>
                    </filter>
                    <filter id="filter2_f_33_117" x="-186" y="-389" width="1811" height="1317"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="77" result="effect1_foregroundBlur_33_117"/>
                    </filter>
                    <filter id="filter3_f_33_117" x="155" y="389" width="791" height="408" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="50.5" result="effect1_foregroundBlur_33_117"/>
                    </filter>
                    <filter id="filter4_f_33_117" x="225" y="-346" width="730" height="629" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="55.5" result="effect1_foregroundBlur_33_117"/>
                    </filter>
                </defs>
            </svg>

            <div className="text-wrapper">
                <div className="main-block-title">Международные валютные переводы под ключ</div>
                <div className="main-block-text">В условиях текущих ограничений по валютным переводам за рубеж, мы
                    предоставляем готовые, проверенные решения по осуществлению трансграничных переводов под ключ
                </div>

                <Button text={"Связаться с нами"} href="#contact" additionalClassname={"button-main-block"}></Button>

            </div>
        </div>
    );
};
export default MainBlock;